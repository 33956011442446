import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import _ from 'lodash'
import Filters from './Filters'
import PDFGenerator from './PDFGenerator'
import TimeframeSelector from './TimeframeSelector'
import styled from 'styled-components/macro'
import { cssvar } from '../../styles/var'
import useScreenSize from '../../utils/useScreenSize'
import { ActiveTimeFilterOption, setActiveTimeFilter } from '../../redux/ducks/filters'

const Toolbar = ({
  linkURL, links, filters, navDrilldownHeader, language, scrollRef,
}) => {
  const router = useHistory()
  const { pathname } = router.location

  const { isMobile } = useScreenSize()

  useEffect(() => {
    setActiveTimeFilter(process.env.REACT_APP_ENV !== 'production' && !isMobile ? ActiveTimeFilterOption.range : ActiveTimeFilterOption.timeframe)
  }, [isMobile])

  const renderFilter = () => {
    const currentUrl = urls => _.some(urls, url => _.includes(pathname, url))
    if (currentUrl(['availability', 'team_injuries', 'competition_injuries', 'roster'])) {
      return (
        <FiltersRow>
          {
            (process.env.REACT_APP_ENV !== 'production')
            && <Filters filters={filters} language={language} showOnlyPicker />
          }
          <TimeframeSelector />
        </FiltersRow>
      )
    }
    if (currentUrl(['news', 'media_stats'])) {
      return <Filters filters={filters} language={language} />
    }
    if (navDrilldownHeader.data.type === 'profile') {
      return <PDFGenerator id={navDrilldownHeader.data.tag} />
    }
    return <div />
  }

  return (
    <ToolbarStyled ref={scrollRef}>
      <FiltersWrapper>
        {renderFilter()}
      </FiltersWrapper>
      <Navigation>
        {
          links.map(link => (
            <Link
              key={link.link}
              to={`${linkURL}/${link.link}`}
              showOnDevices={link.onlyDevices}
              hideOnDevices={link.notResponsive}
              activeClassName="active-toolbar-link"
              replace
            >
              {link.name}
            </Link>
          ))}
      </Navigation>
    </ToolbarStyled>
  )
}

const mapStateToProps = ({ filters, navDrilldownHeader }) => ({
  filters,
  navDrilldownHeader,
})
function mapDispatchToProps(dispatch) {
  return {
    setActiveTimeFilter: (value) => {
      const action = setActiveTimeFilter(value)
      dispatch(action)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)

const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 10px
`

const ToolbarStyled = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  background-color: white;
  @media (max-width: 1024px) {
    padding: 0;
    overflow-x: auto;
  }
  @media (max-width: 1024px) and (min-height: 500px) {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; 
  scrollbar-width: none; 
`
const Navigation = styled.nav`
  flex-wrap: nowrap;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    align-items: stretch;
    justify-content: center;
  }
`
const Link = styled(NavLink)`
  ${props => props.showOnDevices && 'display: none'};
  padding: 16px 14px;
  color: ${cssvar('secondaryTextColor')};
  font-weight: 480;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: ${cssvar('borderColor')};
    border-radius: 2px 2px 0 0;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform .3s, background-color .3s;
  }
  &:hover {
    color: ${cssvar('mainColor')};
    &:before {
      transform: scaleX(1);
    }
  }
  &.active-toolbar-link {
    color: ${cssvar('primaryColor')};
    pointer-events: none;
    &:before {
      background-color: ${cssvar('primaryColor')};
      transform: scaleX(1);
    }
  }
  @media (max-width: 1440px) {
    font-size: 13px;
    padding: 16px 10px;
  }
  @media (max-width: 1230px) {
    font-size: 12px;
    padding: 16px 8px;
  }
  @media (max-width: 1024px) {
    display: flex;
    ${props => props.hideOnDevices && 'display: none'};
    flex-direction: column;
    justify-content: center;
    padding: 16px 10px;
    font-size: 12px;
    line-height: 1.3;
  }
  @media (max-width: 640px) {
    padding: 12px 5px;
    margin: 0px 5px;
    white-space: nowrap;
  }
`
const FiltersWrapper = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`
