import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { BASE_V2_URL } from '../../../api'
import { ActiveTimeFilterOption } from '../../../redux/ducks/filters'
import { cssvar } from '../../../styles/var'
import MobileTimeframeSelector from '../../Generic/MobileTimeframeSelector'
import BarChart from '../../Widgets/BarChart'
import PieChart from '../../Widgets/PieChart'
import { Container } from '../styledComponents'

const CompetitionInjuries = ({ data, filters }) => {
  const { t } = useTranslation()
  const [competitionPayload, setCompetitionPayload] = useState({})

  const {
    tag, competition, teams: teamsMap, players: playersMap,
  } = competitionPayload

  const teams = useMemo(() => {
    if (!teamsMap) return []
    return Object.values(teamsMap)
  }, [teamsMap])

  const injuries = useMemo(() => {
    if (!playersMap) return []

    return _.flatMap(playersMap, player => player.opened_injuries);
  }, [playersMap])

  const onlyParentInjuries = useMemo(() => injuries.filter(injury => !injury.parent_injury_id), [injuries])

  const fetchData = useCallback(
    _.debounce((filters) => {
      async function fetch(filters) {
        try {
          const timeframe = filters.activeTimeFilter === ActiveTimeFilterOption.timeframe
            ? filters.timeframe
            : `${moment(filters.from).format('YYYY-MM-DD')},${moment(filters.to).format('YYYY-MM-DD')}`
          const result = await axios(
            `${BASE_V2_URL}/competitions/${data.tag}?timeframe=${timeframe}`,
          )
          setCompetitionPayload(result.data)
        } catch (err) {
          console.log('Unable to load data for', data.tag)
        }
      }
      fetch(filters)
    }, 300), [],
  )

  useEffect(() => {
    fetchData(filters)
  }, [filters])


  const allTeamsInjuriesData = useMemo(() => (teams ? teams.filter(team => !!team.team).map((team) => {
    const stats = team.stats
    const {
      injuries: {
        total,
        severe,
        injuries_in_match,
        muscular_injuries_in_match,
      }, recovery_days,
      matches: {
        injured,
      },
    } = stats

    return {
      name: team.team.meta.common_name,
      link: team.team.tag,
      Injuries: total,
      SignificantInjuries: severe,
      MinorInjuries: total - severe,
      DaysInjured: recovery_days,
      MissedMatches: injured,
      MatchInjuriesPercent: Math.round(injuries_in_match / total * 100),
      MatchMuscularInjuriesPercent: Math.round(muscular_injuries_in_match / total * 100),
      MatchInjuriesAbsolute: team.stats.injuries.injuries_in_match,
      MatchMuscularInjuriesAbsolute: team.stats.injuries.muscular_injuries_in_match,
    }
  }) : []), [teams])

  const injuriesTypology = _.chain(onlyParentInjuries)
    .groupBy(injury => injury.injury_type)
    .map((injuries, injuryType) => ({
      name: t(`injury-type-${injuryType}`),
      value: _.sumBy(injuries, injury => injury.contexts.length)
    }))
    .sortBy(result => -result.value)
    .value()
  const InjuriesTypesWidget = injuriesTypology.length > 3 ? BarChart : PieChart

  const whereTypology = _.chain(onlyParentInjuries)
    .reject({ where: 'none' })
    .groupBy(injury => injury.where)
    .map((injuries, where) => ({
      name: t(`injury-match-where-${where}`),
      value: _.sumBy(injuries, injury => injury.contexts.length),
    }))
    .orderBy(result => -result.value)
    .value()

  const traumaTypology = _.chain(onlyParentInjuries)
    .reject(injury => !injury.trauma || injury.trauma === 'na')
    .groupBy(injury => injury.trauma)
    .map((injuries, trauma) => ({
      name: t(`injury-contact-${trauma}`),
      value: _.sumBy(injuries, injury => injury.contexts.length),
    }))
    .sortBy(result => -result.value)
    .value();

  const severityTypology = _.chain(onlyParentInjuries)
    .flatMap(inj => inj.contexts)
    .groupBy(ctx => {
      if (ctx.duration < 30) return '< 30 days'
      if (ctx.duration <= 60) return '30-60 days'
      return '> 60 days'
    })
    .map((contexts, severity) => ({
      name: t(severity),
      value: contexts.length
    }))
    .sortBy(result => -result.value)
    .value()

  // Dump command
  if (process.env.REACT_APP_ENV !== 'production') {
    useEffect(() => {
      window.dumpInjuries = () => {
        console.log('Dumping injuries...');

        if (!competitionPayload.players) {
          console.error('No data available to dump.')
          return
        }

        const tmpInjuries = _.chain(competitionPayload.players)
          .flatMap((player) => player.opened_injuries)
          .map(injury => _.omit(injury, ['media', 'news', 'stadium', 'translated_description']))
          .value()

        const blob = new Blob([JSON.stringify({
          tag: data.tag,
          filters: filters && {
            to: filters.to,
            from: filters.from
          },
          injuries: tmpInjuries
        }, null, 2)], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const newTab = window.open(url, '_blank')
        if (newTab) {
          newTab.focus()
        } else {
          console.error('Failed to open a new tab. Please allow popups for this site.')
        }
      };
    }, [competitionPayload]);
  }

  if (!competition) return null

  return (
    <Container>
      <Widgets>
        <MobileTimeframeSelector />
        <Widget>
          <WidgetTitle>
            {t('Injuries')}
            <TotalValue>
              {_.sumBy(allTeamsInjuriesData, 'Injuries')}
              <span>{` / ${_.sumBy(allTeamsInjuriesData, 'SignificantInjuries')}`}</span>
            </TotalValue>
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, ['Injuries', 'SignificantInjuries'])}
            dataKey="Injuries"
            dataKey2="SignificantInjuries"
            nameKey="name"
            linkKey="link" />
        </Widget>
        <Widget>
          <WidgetTitle>
            {t('Days injured')}
            <TotalValue>
              {_.sumBy(allTeamsInjuriesData, 'DaysInjured')}
            </TotalValue>
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, 'DaysInjured')}
            dataKey="DaysInjured"
            nameKey="name"
            linkKey="link" />
        </Widget>
        <Widget>
          <WidgetTitle>
            {t('Missed matches')}
            <TotalValue>
              {_.sumBy(allTeamsInjuriesData, 'MissedMatches')}
            </TotalValue>
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, 'MissedMatches')}
            dataKey="MissedMatches"
            nameKey="name"
            linkKey="link" />
        </Widget>
        <Widget>
          <WidgetTitle>
            {t('Injuries at matchday')}
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, 'MatchInjuriesPercent')}
            dataKey="MatchInjuriesPercent"
            absoluteValueKey="MatchInjuriesAbsolute"
            nameKey="name"
            linkKey="link"
            percents />
        </Widget>
        <Widget>
          <WidgetTitle>
            {t('Muscular injuries at matchday')}
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, 'MatchMuscularInjuriesPercent')}
            dataKey="MatchMuscularInjuriesPercent"
            absoluteValueKey="MatchMuscularInjuriesAbsolute"
            nameKey="name"
            linkKey="link"
            percents />
        </Widget>
        {injuriesTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Injury types')}</WidgetTitle>
            <InjuriesTypesWidget data={injuriesTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}
        {traumaTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('injury-contact')}</WidgetTitle>
            <PieChart data={traumaTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {whereTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Match/Training')}</WidgetTitle>
            <PieChart data={whereTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {severityTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Severity')}</WidgetTitle>
            <PieChart data={severityTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}
      </Widgets>
    </Container>
  )
}

function mapStateToProps({ filters }) {
  return { filters }
}

export default connect(mapStateToProps)(CompetitionInjuries)

const Widgets = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2em;
  @media(max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
    margin-top: 1em;
  }
`
const Widget = styled.div`
  min-width: 0;
  min-height: 0;
  padding: 1em;
  background-color: #fff;
  grid-column: span 2;
  @media(max-width: 1290px) {
    grid-column: span 3;
  }
  &:nth-child(n+5){
    grid-column: span 3;
  }
`
const WidgetTitle = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: 500;
`
const TotalValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${cssvar('primaryColor')};
  white-space: nowrap;
  span {
    color: ${cssvar('injuryColor')};
  }
`