import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { SelectedRowProvider } from './contexts'
import InjuriesOverall from './Overall'
import Heatmap from './Heatmap'
import {
  Container, Main, H1, Disclaimer,
} from '../styledComponents'
import CareerInjuries from '../../Widgets/Availability/CareerInjuries'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'

const { Option } = Select

const selectedRowInitialValue = []
const bodyPartSelectedInitialValue = null

const Injury = ({
  data,
  coverageStatus,
  playerName,
  bodyOnly = false,
  playerId,
  coverageProgress,
  seasons,
}) => {
  const { t } = useTranslation()
  const selectedRowState = useState(selectedRowInitialValue)
  const bodyPartSelectedState = useState(bodyPartSelectedInitialValue)
  const [selectedRows, setSelectedRows] = selectedRowState
  const [, setBodyPartSelected] = bodyPartSelectedState
  const [selectedSeason, setSelectedSeason] = useState('')

  const initializeStates = () => {
    setSelectedRows(selectedRowInitialValue)
    setBodyPartSelected(bodyPartSelectedInitialValue)
  }

  const allSeasons = useMemo(() => {
    const sortedApiSeasons = (_.reverse(_.sortBy(seasons, 'start_date'))).map((season) => {
      const from = moment(season.start_date)
      const to = moment(season.end_date)

      const label = from.year() === to.year() ? from.format('YYYY') : `${from.format('YYYY')}/${to.format('YYYY')}`

      return {
        label,
        from,
        to,
      }
    })

    const oldestApiSeasonFrom = moment(sortedApiSeasons.map(season => season.from).sort()[0])
    const oldestApiSeasonTo = moment(sortedApiSeasons.map(season => season.to).sort()[0])


    const oldestSeasonType = oldestApiSeasonFrom.year() === oldestApiSeasonTo.year() ? 'solar-year' : 'cross-year'

    data.forEach((injury) => {
      const ranges = sortedApiSeasons.map(season => [season.from, season.to])
      const injuryDate = moment(injury.date, 'YYYY-MM-DD')

      if (!ranges.some(range => injuryDate.isBetween(range[0], range[1]))) {
        if (oldestSeasonType === 'solar-year') {
          const from = moment(`${injuryDate.year()}-01-01`, 'YYYY-MM-DD')
          const to = moment(`${injuryDate.year()}-12-31`, 'YYYY-MM-DD')
          const label = `${injuryDate.year()}`

          sortedApiSeasons.push({
            label,
            from,
            to,
          })
        } else {
          const sameYearStart = moment(`${injuryDate.year()}-07-01`, 'YYYY-MM-DD')

          if (injuryDate.isBefore(sameYearStart)) {
            const from = moment(`${injuryDate.year() - 1}-07-01`, 'YYYY-MM-DD')
            const to = moment(`${injuryDate.year()}-06-30`, 'YYYY-MM-DD')
            const label = `${injuryDate.year() - 1}/${injuryDate.year()}`

            sortedApiSeasons.push({
              label,
              from,
              to,
            })
          } else {
            const from = moment(`${injuryDate.year()}-07-01`, 'YYYY-MM-DD')
            const to = moment(`${injuryDate.year() + 1}-06-30`, 'YYYY-MM-DD')
            const label = `${injuryDate.year()}/${injuryDate.year() + 1}`

            sortedApiSeasons.push({
              label,
              from,
              to,
            })
          }
        }
      }
    })

    return sortedApiSeasons
  }, [seasons, data])

  useEffect(initializeStates, [playerName])

  let dataMapped = data
    .filter(({ id }) => !selectedRows.includes(id))
    .map(row => ({
      ...row,
      key: row.id,
      side: [
        'both',
        ...(['pelvis', 'chest'].includes(row.injuried_part) ? ['na', ''] : []),
      ].includes(row.side)
        ? 'both'
        : row.side, // Always mark 'both' and '' as both side, mark 'na' as both only for 'pelvis' and 'chest'
      dateEnd:
        row.recovery_days > 0 && moment(row.date).add(parseInt(row.recovery_days, 10), 'days'),
    }))

  if (selectedSeason) {
    const { from, to } = allSeasons.find(season => season.label === selectedSeason)
    dataMapped = _.filter(dataMapped, injury => moment(injury.date).isBetween(from, to, undefined, '[]'))
  }

  function handleChange(value) {
    setSelectedSeason(value)
  }

  const availableSeasons = _.filter(allSeasons, season => _.some(data, injury => moment(injury.date).isBetween(moment(season.from, 'YYYY-MM-DD'), moment(season.to, 'YYYY-MM-DD'), undefined, '[]')))

  return (
    <Container>
      <SelectedRowProvider value={{ selectedRowState, bodyPartSelectedState }}>
        <MainStyled>
          <PageHeader>
            <LeftSide>
              <H1Styled>{t('injury-data')}</H1Styled>
              <Select defaultValue='' style={{ width: 150 }} onChange={handleChange}>
                <Option value=''>{t('Total injuries')}</Option>
                {_.map(availableSeasons, season => (
                  <Option value={season.label}>{season.label}</Option>
                ))}
              </Select>
            </LeftSide>
            {!bodyOnly && (
              <InjuriesOverall
                data={dataMapped}
                coverageStatus={coverageStatus}
                coverageProgress={coverageProgress}
                playerId={playerId}
                playerName={playerName}
              />
            )}
          </PageHeader>
          <Heatmap
            data={dataMapped}
            playerId={playerId}
            playerName={playerName}
            bodyOnly={bodyOnly}
            coverageStatus={coverageStatus}
            coverageProgress={coverageProgress}
          />
          {!!dataMapped.length && (
            <Disclaimer>
              <p>{t('injury-disclaimer')}</p>
              <p>{t('injury-return-disclaimer')}</p>
              <p>{t('injury-contact-disclaimer')}</p>
            </Disclaimer>
          )}
        </MainStyled>
        {false && (
          <Main>
            <WidgetTitle>
              {t('Career injuries')}
              {' '}
              <span>{data.length}</span>
            </WidgetTitle>
            <CareerInjuries injuries={data} />
          </Main>
        )}
      </SelectedRowProvider>
    </Container>
  )
}

Injury.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      injury_type: PropTypes.string,
      injuried_part: PropTypes.string,
      description: PropTypes.string,
      side: PropTypes.string,
      date: PropTypes.string,
      recovery_days: PropTypes.string,
    }),
  ),
  playerName: PropTypes.string.isRequired,
}

Injury.defaultProps = {
  data: [],
}

export default Injury

const MainStyled = styled(Main)`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 30px;
    @media (max-width: 1024px) {
      margin-bottom: 15px;
    }
  }
`
const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  min-height: 90px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 30px;
  }
  @media (max-width: 640px) {
    align-items: flex-end;
  }
`
const LeftSide = styled.div`
  flex: 0 0 auto;
  flex-direction: column;
  display: flex;
  align-self: stretch;
  justify-content: flex-start;
`
const H1Styled = styled(H1)`
  @media (max-width: 1024px) {
    display: none;
  }
`
const WidgetTitle = styled.h4`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-top: -2px;
    padding: 3px 8px;
    border: 2px solid ${cssvar('injuryColor')};
    border-radius: 100px;
    color: ${cssvar('injuryColor')};
    font-size: 13px;
    font-weight: 650;
    line-height: 1;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    span {
      margin-left: 5px;
      font-size: 11px;
    }
  }
`
