import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BASE_V2_URL } from '../../../api'
import { Container } from '../styledComponents'
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
} from 'recharts'
import MobileTimeframeSelector from '../../Generic/MobileTimeframeSelector'
import Table from '../../Table'
import PlayerCell from '../../Table/PlayerCell'
import BarChart from '../../Widgets/BarChart'
import PieChart from '../../Widgets/PieChart'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'

import {
  Button,
  ButtonWrapper,
  EmptyMessage,
  Form,
  Label,
  RequestLink,
  TextArea,
} from '../Injury/EmptyState'
import Dialog from '../../Dialog'
import { Select } from 'antd'
import Formatter from '../../../utils/Formatter'
import { ActiveTimeFilterOption } from '../../../redux/ducks/filters'

const TeamInjuries = ({ data, filters }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [roster, setRoster] = useState({ players: [] })
  const [dialog, setDialog] = useState(false)

  const history = useHistory()

  const fetchData = useCallback(
    _.debounce((filters) => {
      async function fetch(filters) {
        try {
          const timeframe = filters.activeTimeFilter === ActiveTimeFilterOption.timeframe
            ? filters.timeframe
            : `${moment(filters.from).format('YYYY-MM-DD')},${moment(filters.to).format('YYYY-MM-DD')}`

          const result = await axios(
            `${BASE_V2_URL}/teams/${data.tag}?timeframe=${timeframe}`,
          )
          setLoading(false)
          setRoster({ players: Object.values(result.data.players) })
        } catch (err) {
          console.log('Unable to load data for', data.tag)
        }
      }
      fetch(filters)
    }, 300),
    [],
  )

  useEffect(() => {
    fetchData(filters)
  }, [filters])

  const weeks = () => {
    if (filters.timeframe === 'current_season') {
      const seasonStartDate = moment(data.seasons.find(season => season.value === 'current_season').start_date)
      return _.reverse(_.times(moment().diff(seasonStartDate, 'weeks') + 2))
    }
    if (filters.timeframe === 'last_6_months') return _.reverse(_.times(27))
    if (filters.timeframe === 'last_3_months') return _.reverse(_.times(14))

    if (filters.ignoreTimeframe) {
      const from = moment(filters.from)
      const to = moment(filters.to)
      return _.reverse(_.times(Math.round(to.diff(from, 'weeks', true) + 1)))
    }

    return _.reverse(_.times(53))
  }

  const injuries = useMemo(() => roster.players.flatMap(p => p.opened_injuries).filter(injury => !injury.parent_injury_id), [roster])

  const filteredInjuries = useMemo(() => injuries.filter(injury => !injury.parent_injury_id), [injuries])

  const injuriesByDay = _.map(_.reverse(weeks()), (week) => {
    const selectedSeason = data.seasons.find(season => season.value === filters.timeframe)

    const seasonEndDate = selectedSeason ? moment(selectedSeason.end_date) : null
    const seasonStartDate = selectedSeason ? moment(selectedSeason.start_date) : null

    const now = moment()

    const timeframeEnd = selectedSeason && seasonEndDate && now.isAfter(seasonEndDate) ? seasonEndDate : moment()
    const timeframeStart = selectedSeason ? seasonStartDate : timeframeEnd.subtract((weeks()).length - 1, 'weeks')


    const date = moment(timeframeStart).add(week, 'weeks').isBefore(now) ? moment(timeframeStart).add(week, 'weeks') : now

    const currentlyInjured = _.filter(injuries, i => (
      moment(i.date).isBefore(date)
      && (moment(i.date).add(i.recovery_days, 'days').isAfter(date) || !i.recovery_days)
    ))

    return {
      name: date.format(filters.timeframe === 'last_season' ? 'DD.MM.YYYY' : 'DD.MM'),
      InjuriedPlayers: currentlyInjured.length,
    }
  })

  const columns = [
    { title: t('Player'), render: row => <PlayerCell image={row.player.profile_data.image} name={row.player.profile_data.label} /> },

    {
      title: t('Position'),
      hideOnBreakpoint: true,
      render: row => (row.stats
        ? _.upperCase(row.stats.primary_position).replace(' 3', '').replace(' 5', '') || '-'
        : '-'),
    },
    {
      title: t('injuries_number_short'),
      dataIndex: ['injuries_count'],
      sortable: true,
      align: 'right',
    },
    {
      title: t('Matches played'),
      dataIndex: ['stats', 'matches', 'played'],
      align: 'right',
      sortable: true,
    },
    {
      title: t('Matches on bench'),
      dataIndex: ['stats', 'matches', 'bench'],
      align: 'right',
      sortable: true,
      hideOnBreakpoint: true,
    },
    {
      title: t('Minutes played'),
      dataIndex: ['stats', 'minutes_played'],
      sortable: true,
      align: 'right',
      hideOnBreakpoint: true,
    },
    {
      title: t('Matches injured'),
      dataIndex: ['stats', 'matches', 'injured'],
      sortable: true,
      align: 'right',
      render: matches => <InjuryStat>{matches}</InjuryStat>,
    },

    {
      title: t('Days injured'),
      dataIndex: ['stats', 'recovery_days'],
      sortable: true,
      align: 'right',
      render: days => <InjuryStat>{days || '-'}</InjuryStat>,
    },
  ]

  const players = _.reject(roster.players, p => p.player.profile_data.meta.role === 'Unknown')
  const injuriedPlayers = _.groupBy(injuries, 'player_key')
  const filteredPlayers = players.filter(
    p => p.stats && _.includes(Object.keys(injuriedPlayers), p.tag),
  ).map(p => ({
    ...p,
    injuries_count: p.opened_injuries.filter(injury => !injury.parent_injury_id).length,
  }))

  const goToPlayer = (row) => {
    history.push(`/explore/${row.tag}`)
  }

  const injuriesTypology = _.reverse(
    _.sortBy(
      _.map(_.groupBy(injuries, 'injury_type'), inj => ({
        name: t(`injury-type-${inj[0].injury_type}`),
        value: inj.length,
      })),
      'value',
    ),
  )
  const InjuriesTypesWidget = injuriesTypology.length > 3 ? BarChart : PieChart

  const whereTypology = _.map(_.groupBy(_.reject(injuries, { where: 'none' }), 'where'), inj => ({
    name: t(`injury-match-where-${inj[0].where}`),
    value: inj.length,
  }))

  const traumaTypology = _.map(
    _.groupBy(
      _.reject(injuries, i => i.trauma === 'na'),
      'trauma',
    ),
    inj => ({ name: t(`injury-contact-${inj[0].trauma}`), value: inj.length }),
  )

  const severityTypology = _.chain(injuries)
    .flatMap(inj => inj.contexts)
    .groupBy(ctx => {
      if (ctx.duration < 30) return '< 30 days'
      if (ctx.duration <= 60) return '30-60 days'
      return '> 60 days'
    })
    .map((contexts, severity) => ({
      name: t(severity),
      value: contexts.length
    }))
    .sortBy(result => -result.value)
    .value()

  const rowStyle = (player) => {
    if (player.current_status.ongoing_injuries && player.current_status.ongoing_injuries.length > 0) {
      return css`
      td {
        background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.05)};
        &:hover {
          background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.08)};
        }
      }
    `
    }
    return {}
  }

  // Dump command
  if (process.env.REACT_APP_ENV !== 'production') {
    useEffect(() => {
      window.dumpInjuries = () => {
        console.log('Dumping injuries...');

        if (!injuries) {
          console.error('No data available to dump.')
          return
        }

        const tmpInjuries = _.chain(injuries)
          .map(injury => _.omit(injury, ['media', 'news', 'stadium', 'translated_description']))
          .value()

        const blob = new Blob([JSON.stringify({
          tag: data.tag,
          filters: filters && {
            to: filters.to,
            from: filters.from
          },
          injuries: tmpInjuries
        }, null, 2)], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const newTab = window.open(url, '_blank')
        if (newTab) {
          newTab.focus()
        } else {
          console.error('Failed to open a new tab. Please allow popups for this site.')
        }
      };
    }, [injuries]);
  }

  return (
    <Container>
      <Widgets>
        <MobileTimeframeSelector />
        {injuries.length > 0 && (
          <>
            <Widget fullWidth>
              <GeneralStats>
                <div>
                  <WidgetTitle>{t('Total injuries')}</WidgetTitle>
                  <GeneralStat>{filteredInjuries.length}</GeneralStat>
                </div>
                <div>
                  <WidgetTitle>{t('Total matches missed')}</WidgetTitle>
                  <GeneralStat>
                    {_.sumBy(filteredPlayers, 'stats.matches.injured')}
                  </GeneralStat>
                </div>
                <div>
                  <WidgetTitle>{t('Total days missed')}</WidgetTitle>
                  <GeneralStat>
                    {_.sumBy(_.map(filteredPlayers, 'stats.recovery_days'), days => Number(days))}
                  </GeneralStat>
                </div>
              </GeneralStats>
              <LineChartContainer>
                <LineChartSubContainer>
                  <ResponsiveContainer width='99%' height={220}>
                    <LineChart
                      width={730}
                      height={250}
                      data={injuriesByDay}
                      margin={{
                        top: 5, right: 20, left: -30, bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray='1 1' vertical={false} />
                      <XAxis
                        dataKey='name'
                        tickLine={false}
                        tick={{ fill: cssvar('secondaryTextColor'), fontSize: 12, dy: 6 }}
                        interval='preserveStartEnd'
                      />
                      <YAxis
                        type='number'
                        domain={[0, dataMax => (dataMax > 7 ? dataMax + 1 : 8)]}
                        tickLine={false}
                        tick={{ fill: cssvar('secondaryTextColor'), fontSize: 12, dx: 0 }}
                      />
                      <Tooltip
                        formatter={(value, name) => [value, t(_.upperFirst(_.lowerCase(name)))]}
                      />
                      <Line
                        type='monotone'
                        dataKey='InjuriedPlayers'
                        stroke='#C72E1F'
                        strokeWidth={3}
                        isAnimationActive={false}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </LineChartSubContainer>
              </LineChartContainer>
            </Widget>

            <Widget fullWidth>
              <WidgetTitle>
                {t(
                  `Injured ${filters.timeframe === 'last_season' ? 'last' : 'this'} ${filters.timeframe === 'year' ? 'year' : 'season'
                  }`,
                )}
                {' '}
                <span>{filteredPlayers.length}</span>
              </WidgetTitle>
              <Table
                columns={columns}
                data={filteredPlayers}
                defaultSorting={['stats', 'recovery_days']}
                hideColumnsBreakpoint='max-width: 640px'
                onRowClick={goToPlayer}
                rowStyle={rowStyle}
              />
            </Widget>

            {injuriesTypology.length > 0 && (
              <Widget>
                <WidgetTitle>{t('Injury types')}</WidgetTitle>
                <InjuriesTypesWidget data={injuriesTypology} dataKey='value' nameKey='name' />
              </Widget>
            )}
            {traumaTypology.length > 0 && (
              <Widget>
                <WidgetTitle>{t('injury-contact')}</WidgetTitle>
                <PieChart data={traumaTypology} dataKey='value' nameKey='name' />
              </Widget>
            )}

            {whereTypology.length > 0 && (
              <Widget>
                <WidgetTitle>{t('Match/Training')}</WidgetTitle>
                <PieChart data={whereTypology} dataKey='value' nameKey='name' />
              </Widget>
            )}

            {severityTypology.length > 0 && (
              <Widget>
                <WidgetTitle>{t('Severity')}</WidgetTitle>
                <PieChart data={severityTypology} dataKey='value' nameKey='name' />
              </Widget>
            )}
          </>
        )}
      </Widgets>

      {!loading && injuries.length === 0 && (
        <Widget fullWidth>
          <EmptyMessageStyled>
            {t('No injuries recorded')}
            <RequestLink>
              {t('Need to get updated info about this team?')}
              {' '}
              <span onClick={() => setDialog(true)}>{t('Open a ticket')}</span>
            </RequestLink>
          </EmptyMessageStyled>
        </Widget>
      )}

      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        title={t('Information request')}
        subtitle={t('Please fill the form')}
        footer={(
          <ButtonWrapper>
            <Button onClick={() => setDialog(false)}>{t('Submit request')}</Button>
          </ButtonWrapper>
        )}
      >
        <Form>
          <Label>{t('Request category')}</Label>
          <Select defaultValue='1' style={{ width: '100%' }}>
            <Select.Option value='1'>{t('Missing data')}</Select.Option>
            <Select.Option value='2'>{t('Update required')}</Select.Option>
          </Select>
          <Label>{t('Describe the info you\'d like to request')}</Label>
          <TextArea rows='5' />
        </Form>
      </Dialog>
    </Container>
  )
}

TeamInjuries.defaultProps = {
  data: [],
}

function mapStateToProps({ filters }) {
  return { filters }
}

export default connect(mapStateToProps)(TeamInjuries)

const Widgets = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`
const Widget = styled.div`
  ${props => props.fullWidth
    && css`
      @media (min-width: 1025px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 15px;
  background-color: #fff;
  .recharts-surface {
    margin-top: -4px;
  }
`
const WidgetTitle = styled.h4`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-top: -2px;
    padding: 3px 8px;
    border: 2px solid ${cssvar('injuryColor')};
    border-radius: 100px;
    color: ${cssvar('injuryColor')};
    font-size: 13px;
    font-weight: 650;
    line-height: 1;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    span {
      margin-left: 5px;
      font-size: 11px;
    }
  }
`
const GeneralStats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  padding: 15px 0;
  text-align: center;
  ${WidgetTitle} {
    margin-bottom: 5px;
  }
  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding-top: 0;
    padding-bottom: 20px;
    ${WidgetTitle} {
      font-size: 16px;
    }
  }
`
const GeneralStat = styled.div`
  font-size: 2em;
  font-weight: 550;
  line-height: 1;
  color: #c72e1f;
  @media (max-width: 640px) {
    font-size: 20px;
  }
`
const LineChartContainer = styled.div`
  width: 100%;
  height: 220px;
  position: relative;
`
const LineChartSubContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`
const InjuryStat = styled.span`
  color: #c72e1f;
  font-weight: 500;
`

const EmptyMessageStyled = styled(EmptyMessage)`
  text-align: center;
  position: static;
  transform: none;
`
